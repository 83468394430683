var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext } from "react";
import { useState } from "react";
import Cookies from 'js-cookie';
var PublicRoomContext = createContext();
export var PublicRoomProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var getCurrentUserId = document.getElementById("current-auth-user-id").value;
    var currentUserFirstName = document.getElementById("current-auth-user-first-name").value;
    var currentUserLastName = document.getElementById("current-auth-user-last-name").value;
    //current user State
    var _c = __read(useState(getCurrentUserId), 2), currentUserId = _c[0], setCurrentUser = _c[1];
    //Search State 
    var _d = __read(useState(""), 2), searchInput = _d[0], setSearchInput = _d[1];
    var _e = __read(useState(""), 2), searchedTerm = _e[0], setSearchedTerm = _e[1];
    // Channels State
    var _f = __read(useState([]), 2), channels = _f[0], setChannels = _f[1];
    var _g = __read(useState(null), 2), selectedChannel = _g[0], setSelectedChanel = _g[1];
    var _h = __read(useState(false), 2), channelsLoading = _h[0], setChannelsLoading = _h[1];
    //Thread State
    var _j = __read(useState([]), 2), threads = _j[0], setThreads = _j[1];
    var _k = __read(useState(false), 2), threadIsEditing = _k[0], setThreadIsEditing = _k[1];
    var _l = __read(useState(""), 2), threadIdToEdit = _l[0], setThreadIdToEdit = _l[1];
    //Message State 
    var _m = __read(useState([]), 2), messages = _m[0], setMessages = _m[1];
    var _o = __read(useState(""), 2), entredMessage = _o[0], setEntredMessage = _o[1];
    var _p = __read(useState(false), 2), messageIsEditing = _p[0], setMessageEditing = _p[1];
    var _q = __read(useState(""), 2), messageIdToEdit = _q[0], setMessageIdToEdit = _q[1];
    // Modal State
    var _r = __read(useState(false), 2), modalIsOpen = _r[0], setModalIsOpen = _r[1];
    var _s = __read(useState(false), 2), confirmationModalIsOpen = _s[0], setConfirmationModalIsOpen = _s[1];
    // Alert message State
    var _t = __read(useState(false), 2), showAlertMessage = _t[0], setShowAlertMessage = _t[1];
    //New Thread State 
    var _u = __read(useState(""), 2), threadContent = _u[0], setThreadContent = _u[1];
    var _v = __read(useState(""), 2), threadTitle = _v[0], setThreadTitle = _v[1];
    //State for infinit scroll
    var _w = __read(useState(false), 2), newThreadIsLoading = _w[0], setNewThreadIsLoading = _w[1];
    var _x = __read(useState(false), 2), hasMoreData = _x[0], setHasMoreData = _x[1];
    var _y = __read(useState(""), 2), nextThreads = _y[0], setNextThreads = _y[1];
    React.useEffect(function () {
        fetchChanels(selectedChannel);
    }, []);
    React.useEffect(function () {
        searchChannels(searchedTerm);
    }, [searchedTerm]);
    React.useEffect(function () {
        if (selectedChannel) {
            getThreads(selectedChannel);
        }
    }, [selectedChannel]);
    var loadNewThreads = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (newThreadIsLoading || !hasMoreData) {
                return [2 /*return*/];
            }
            setNewThreadIsLoading(true);
            fetch("".concat(nextThreads))
                .then(function (response) { return response.json(); })
                .then(function (data) {
                data.next ? setHasMoreData(true) : setHasMoreData(false);
                setThreads(__spreadArray(__spreadArray([], __read(threads), false), __read(data.results), false));
            }).catch(function (e) { return console.log(e); });
            setNewThreadIsLoading(false);
            return [2 /*return*/];
        });
    }); };
    //reset Inputs 
    var resetInputs = function () {
        setEntredMessage("");
        setThreadContent("");
        setThreadTitle("");
    };
    // get Channels
    var fetchChanels = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, channels;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setChannelsLoading(true);
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    channels = _a.sent();
                    setChannels(channels.results);
                    setChannelsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    //Search Channels 
    var searchChannels = function (searchedTerm) { return __awaiter(void 0, void 0, void 0, function () {
        var response, channels;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/?search=").concat(searchedTerm))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    channels = _a.sent();
                    setChannels(channels.results);
                    return [2 /*return*/];
            }
        });
    }); };
    //search channel
    var handleSearch = function () {
        setSearchedTerm(searchInput);
    };
    //handle search input change
    var handleSearchInput = function (e) {
        setSearchInput(e.target.value);
    };
    // handleKeyDown function
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setSearchedTerm(searchInput);
        }
    };
    //get Threads 
    var getThreads = function (selectedChannel) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(selectedChannel, "/threads"))
                        .then(function (response) { return response.json(); })
                        .then(function (data) {
                        setThreads(data.results);
                        data.next && setHasMoreData(true);
                        setNextThreads(data.next);
                        setThreads(data.results);
                    }).catch(function (e) { console.log(e); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    //get Messages 
    var getMessages = function (e, selectedChannel, threadId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, newMessages, messageId, updatedMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(selectedChannel, "/threads/").concat(threadId, "/messages/?limit=5000"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    newMessages = { thread_id: threadId, messages: data.results, responseNbr: data.results.length };
                    messageId = messages.findIndex(function (obj) { return obj.thread_id === threadId; });
                    // when there is messages
                    if (messageId != -1) {
                        updatedMessage = messages.map(function (msg) {
                            if (msg.id === threadId) {
                                return newMessages;
                            }
                            return msg;
                        });
                        setMessages(updatedMessage);
                    }
                    else {
                        setMessages(__spreadArray(__spreadArray([], __read(messages), false), [newMessages], false));
                    }
                    displayMessages(threadId);
                    return [2 /*return*/];
            }
        });
    }); };
    //Create a thread
    var CSRF_TOKEN = Cookies.get('csrftoken');
    var createThread = function (e, chanelid) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    threadTitle == "" ? document.getElementById('thread-title').classList.add("is-invalid") :
                        document.getElementById('thread-title').classList.remove("is-invalid");
                    threadContent == "" ? document.getElementById('Content').classList.add("is-invalid") :
                        document.getElementById('Content').classList.remove("is-invalid");
                    if (!(threadTitle != "" && threadContent != "")) return [3 /*break*/, 2];
                    resetInputs();
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelid, "/threads/"), {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "same-origin",
                            headers: {
                                'X-CSRFToken': CSRF_TOKEN,
                                "Content-Type": "application/json",
                                // 'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            redirect: "follow",
                            referrerPolicy: "no-referrer",
                            body: JSON.stringify({ "title": threadTitle, "content": threadContent }), // body data type must match "Content-Type" header
                        }).then(function (response) { return response.json(); })
                            .then(function (thread) {
                            // Access the created object returned by the API
                            setThreads(__spreadArray([thread], __read(threads), false));
                        })];
                case 1:
                    _a.sent();
                    setModalIsOpen(false);
                    resetInputs();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    //delete a Thread 
    var deleteThread = function (e, chanelId, threadId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId), {
                        method: "DELETE",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            'X-CSRFToken': CSRF_TOKEN,
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer",
                        body: JSON.stringify({ "title": threadTitle, "content": threadContent }), // body data type must match "Content-Type" header
                    }).then(function () {
                        var updateThreads = threads.filter(function (thread) { return thread.id !== threadId; });
                        setThreads(updateThreads);
                    })];
                case 1:
                    _a.sent();
                    setConfirmationModalIsOpen(false);
                    return [2 /*return*/];
            }
        });
    }); };
    //Edit thread 
    var getThreadToEdit = function (e, chanelId, threadId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, thread;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setThreadIsEditing(true);
                    setThreadIdToEdit(threadId);
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    thread = _a.sent();
                    setThreadTitle(thread.title);
                    setThreadContent(thread.content);
                    setModalIsOpen(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var editThread = function (e, chanelId, threadId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    threadTitle == "" ? document.getElementById('thread-title').classList.add("is-invalid") :
                        document.getElementById('thread-title').classList.remove("is-invalid");
                    threadContent == "" ? document.getElementById('Content').classList.add("is-invalid") :
                        document.getElementById('Content').classList.remove("is-invalid");
                    if (!(threadTitle != "" && threadContent != "")) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/"), {
                            method: "PUT",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "same-origin",
                            headers: {
                                'X-CSRFToken': CSRF_TOKEN,
                                "Content-Type": "application/json",
                                // 'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            redirect: "follow",
                            referrerPolicy: "no-referrer",
                            body: JSON.stringify({ "title": threadTitle, "content": threadContent }), // body data type must match "Content-Type" header
                        }).then(function (response) { return response.json(); })
                            .then(function () {
                            var updatedThreads = threads.map(function (thread) {
                                if (thread.id === threadId) {
                                    return __assign(__assign({}, thread), { title: threadTitle, content: threadContent });
                                }
                                return thread;
                            });
                            setThreads(updatedThreads);
                        })];
                case 1:
                    _a.sent();
                    setModalIsOpen(false);
                    setThreadIsEditing(false);
                    resetInputs();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    // create a message 
    var createMessage = function (e, chanelid, threadId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!entredMessage) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelid, "/threads/").concat(threadId, "/messages/"), {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "same-origin",
                            headers: {
                                'X-CSRFToken': CSRF_TOKEN,
                                "Content-Type": "application/json",
                                // 'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            redirect: "follow",
                            referrerPolicy: "no-referrer",
                            body: JSON.stringify({ "content": "".concat(entredMessage) }), // body data type must match "Content-Type" header
                        }).then(function (response) { return response.json(); })
                            .then(function (message) {
                            var updatedMessage = messages.map(function (msg) {
                                if (msg.thread_id === threadId) {
                                    var newResponseNbr = msg.responseNbr + 1;
                                    return __assign(__assign({}, msg), { messages: __spreadArray(__spreadArray([], __read(msg.messages), false), [message], false), responseNbr: newResponseNbr });
                                }
                                return msg;
                            });
                            setMessages(updatedMessage);
                        })];
                case 1:
                    _a.sent();
                    // setLatestMessage([...latestMessage,{date: Date.now(),channel_id:chanelid }]);
                    resetInputs();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    //Delete Message 
    var deleteMessage = function (e, chanelId, threadId, messageId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/messages/").concat(messageId, "/"), {
                        method: "DELETE",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            'X-CSRFToken': CSRF_TOKEN,
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer",
                        body: JSON.stringify({ "title": threadTitle, "content": threadContent }), // body data type must match "Content-Type" header
                    }).then(function () {
                        var updatedMessage = messages.map(function (msg) {
                            if (msg.thread_id === threadId) {
                                var newResponseNbr = msg.responseNbr - 1;
                                var updatedMessages = msg.messages.filter(function (message) { return message.id !== messageId; });
                                return __assign(__assign({}, msg), { messages: updatedMessages, responseNbr: newResponseNbr });
                            }
                            return msg;
                        });
                        setMessages(updatedMessage);
                    })];
                case 1:
                    _a.sent();
                    setConfirmationModalIsOpen(false);
                    return [2 /*return*/];
            }
        });
    }); };
    //Edit message
    var getMessageToEdit = function (e, chanelId, threadId, messageId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setMessageEditing(true);
                    setMessageIdToEdit(messageId);
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/messages/").concat(messageId))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    message = _a.sent();
                    setEntredMessage(message.content);
                    return [2 /*return*/];
            }
        });
    }); };
    var editMessage = function (e, chanelId, threadId, messageId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/messages/").concat(messageId, "/"), {
                        method: "PUT",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            'X-CSRFToken': CSRF_TOKEN,
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer",
                        body: JSON.stringify({ "content": entredMessage }), // body data type must match "Content-Type" header
                    }).then(function () {
                        var updatedMessages = messages.map(function (msg) {
                            if (msg.thread_id === threadId) {
                                var updatedMessages_1 = msg.messages.map(function (message) {
                                    if (message.id === messageId) {
                                        return __assign(__assign({}, message), { content: entredMessage });
                                    }
                                    return message;
                                });
                                return __assign(__assign({}, msg), { messages: updatedMessages_1 });
                            }
                            return msg;
                        });
                        setMessages(updatedMessages);
                    })];
                case 1:
                    _a.sent();
                    resetInputs();
                    setMessageEditing(false);
                    return [2 /*return*/];
            }
        });
    }); };
    //Show messages 
    var _z = __read(React.useState(null), 2), messagesToShow = _z[0], setMessagesToShow = _z[1];
    var displayMessages = function (threadId) {
        if (messagesToShow === threadId) {
            setMessagesToShow(null);
        }
        else {
            setMessagesToShow(threadId);
        }
    };
    //handle like a thread 
    var likeThread = function (e, chanelId, threadId) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedThreads;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/like/"), {
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            'X-CSRFToken': CSRF_TOKEN,
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    })];
                case 1:
                    _a.sent();
                    updatedThreads = __spreadArray([], __read(threads), false);
                    updatedThreads = threads.map(function (thread) {
                        if (thread.id === threadId) {
                            return __assign(__assign({}, thread), { like_count: thread.like_count + 1, likes: __spreadArray(__spreadArray([], __read(thread.likes), false), [{ id: threadId, user: { id: currentUserId, first_name: currentUserFirstName, last_name: currentUserLastName } }], false) });
                        }
                        return thread;
                    });
                    setThreads(updatedThreads);
                    return [2 /*return*/];
            }
        });
    }); };
    //handle unlike a thread 
    var unlikeThread = function (e, chanelId, threadId) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedThreads;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/unlike/"), {
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            'X-CSRFToken': CSRF_TOKEN,
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    })];
                case 1:
                    _a.sent();
                    updatedThreads = threads.map(function (thread) {
                        if (thread.id === threadId) {
                            var userLikeIndex = thread.likes.findIndex(function (like) { return like.user.id == currentUserId; });
                            if (userLikeIndex > -1) {
                                var updatedLikes = __spreadArray([], __read(thread.likes), false);
                                updatedLikes.splice(userLikeIndex, 1);
                                return __assign(__assign({}, thread), { like_count: thread.like_count - 1, likes: updatedLikes });
                            }
                        }
                        return thread;
                    });
                    setThreads(updatedThreads);
                    return [2 /*return*/];
            }
        });
    }); };
    //like a message 
    var likeMessage = function (e, chanelId, threadId, messageId) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedMessages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/messages/").concat(messageId, "/like/"), {
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            'X-CSRFToken': CSRF_TOKEN,
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    })];
                case 1:
                    _a.sent();
                    updatedMessages = messages.map(function (message, i) {
                        if (message.thread_id === threadId) {
                            var messageIndx = message.messages.findIndex(function (obj) { return obj.id === messageId; });
                            var newLike = {
                                id: i,
                                user: { id: currentUserId, first_name: currentUserFirstName, last_name: currentUserLastName },
                            };
                            message.messages[messageIndx].likes.push(newLike);
                            message.messages[messageIndx].like_count++;
                        }
                        return message;
                    });
                    setMessages(updatedMessages);
                    return [2 /*return*/];
            }
        });
    }); };
    //unlike Message 
    var unlikeMessage = function (e, chanelId, threadId, messageId) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedMessages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/public-rooms/channels/").concat(chanelId, "/threads/").concat(threadId, "/messages/").concat(messageId, "/unlike/"), {
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            'X-CSRFToken': CSRF_TOKEN,
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    })];
                case 1:
                    _a.sent();
                    updatedMessages = messages.map(function (message) {
                        if (message.thread_id === threadId) {
                            var messageIndx = message.messages.findIndex(function (obj) { return obj.id === messageId; });
                            var newLikes = message.messages[messageIndx].likes.filter(function (like) { return like.user.id != currentUserId; });
                            message.messages[messageIndx].like_count--;
                            message.messages[messageIndx].likes = newLikes;
                        }
                        return message;
                    });
                    setMessages(updatedMessages);
                    return [2 /*return*/];
            }
        });
    }); };
    //Show tooltip
    var _0 = __read(React.useState(null), 2), tooltipToShow = _0[0], setTooltipToShow = _0[1];
    //Show Modal
    var handleShowThreadForm = function () {
        if (selectedChannel) {
            setShowAlertMessage(false);
            setModalIsOpen(true);
        }
        else {
            setShowAlertMessage(true);
            setModalIsOpen(false);
        }
    };
    var handleHideThreadForm = function () {
        setModalIsOpen(false);
    };
    //handle alert message
    var handleAlertMessage = function () {
        setShowAlertMessage(false);
    };
    //handle channel click
    var handleChannelSelection = function (id) {
        setSelectedChanel(id);
        setShowAlertMessage(false);
        if (window.innerWidth < 769) {
            var k = document.getElementsByClassName('pr-row-right')[0];
            k.style.transform = 'translate(0)';
        }
    };
    //handle Thread Content 
    var handleThreadContent = function (e) {
        setThreadContent(e.target.value);
    };
    //handle Thread Title 
    var handleThreadTitle = function (e) {
        setThreadTitle(e.target.value);
    };
    //handle Message 
    var handleMessage = function (e) {
        setEntredMessage(e.target.value);
    };
    return (_jsx(PublicRoomContext.Provider, __assign({ value: {
            handleChannelSelection: handleChannelSelection,
            setTooltipToShow: setTooltipToShow,
            tooltipToShow: tooltipToShow,
            messagesToShow: messagesToShow,
            channels: channels,
            selectedChannel: selectedChannel,
            threads: threads,
            createThread: createThread,
            modalIsOpen: modalIsOpen,
            handleHideThreadForm: handleHideThreadForm,
            handleShowThreadForm: handleShowThreadForm,
            handleThreadContent: handleThreadContent,
            threadContent: threadContent,
            handleThreadTitle: handleThreadTitle,
            threadTitle: threadTitle,
            createMessage: createMessage,
            handleMessage: handleMessage,
            entredMessage: entredMessage,
            deleteThread: deleteThread,
            confirmationModalIsOpen: confirmationModalIsOpen,
            setConfirmationModalIsOpen: setConfirmationModalIsOpen,
            deleteMessage: deleteMessage,
            getThreadToEdit: getThreadToEdit,
            editThread: editThread,
            threadIsEditing: threadIsEditing,
            threadIdToEdit: threadIdToEdit,
            getMessageToEdit: getMessageToEdit,
            editMessage: editMessage,
            messageIsEditing: messageIsEditing,
            messageIdToEdit: messageIdToEdit,
            likeThread: likeThread,
            getMessages: getMessages,
            messages: messages,
            currentUserId: currentUserId,
            setCurrentUser: setCurrentUser,
            unlikeThread: unlikeThread,
            likeMessage: likeMessage,
            unlikeMessage: unlikeMessage,
            newThreadIsLoading: newThreadIsLoading,
            loadNewThreads: loadNewThreads,
            nextThreads: nextThreads,
            hasMoreData: hasMoreData,
            handleSearchInput: handleSearchInput,
            handleSearch: handleSearch,
            handleKeyDown: handleKeyDown,
            channelsLoading: channelsLoading,
            showAlertMessage: showAlertMessage,
            handleAlertMessage: handleAlertMessage
        } }, { children: children })));
};
export default PublicRoomContext;
